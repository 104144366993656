import React from 'react'
import {Helmet} from 'react-helmet'


export default function Play() {
    return (
		<div className="overlay wrap">
			<Helmet>
                <link rel="alternate" href="https://stg.app.peech-ai.com/oembed/0asdfgd4d" type="application/json+oembed" title="Tan-Tan test" />
            </Helmet>

            <iframe src="https://cdn.jwplayer.com/players/GnqjUrKN-Z1rd8mn4.html" allowFullScreen scrolling="auto" style={{
                width: '848px',
                height: '480px'
            }} />
		</div>
	)
}
